<template>
  <div class="history">
    <h3>わせだ寄席の歴史</h3>
    <p>過去から現在までの番組を見ると落語界の変遷も伺える、<br>貴重な資料となっています。（敬称略）<br><br></p>
    
    <!--ElementPlusのel-tree機能を使って過去のわせだ寄席情報を表示。具体的な内容はScriptから-->
    <ul class="class00">
      <el-tree style=" background-color: var(--third-color), overflow: scroll;" :data="data" :props="defaultProps"
        @node-click="handleNodeClick" />
    </ul>
  </div>
</template>

<script>
export default {
  name: 'PastEventInfo',
  props: {},

  /*const handleNodeClick = (data) => {
  console.log(data);
},*/

  data() {
    return {
      data: [
        {
          "label": "落語研究会時代：1954（昭和29）年～1959（昭和34）年",
          "children": [
            {
              "label": "第1回落語研究会（昭和29年6月16日）",
              "children": [
                { "leaf": true, "label": "「粗忽長屋」熊谷光吉" },
                { "leaf": true, "label": "「鼻ほしい」柳家小山三" },
                { "leaf": true, "label": "「粗忽の使者」昔家今松" },
                { "leaf": true, "label": "「寄席芸人噂話」正岡容" },
                { "leaf": true, "label": "対談「噺家の作法」三遊亭圓馬・正岡容" },
                { "leaf": true, "label": "「せむし長屋」三遊亭圓馬" },
                { "leaf": true, "label": "「青菜」柳家小さん" }
              ]
            },
            {
              "label": "第2回落語研究会（昭和29年11月5日）",
              "children": [
                { "leaf": true, "label": "「親子酒」桂小南" },
                { "leaf": true, "label": "「堀の内」古今亭志ん生" },
                { "leaf": true, "label": "「子は鎹」春風亭柳好" },
                { "leaf": true, "label": "「火焔太鼓」三遊亭圓馬" },
                { "leaf": true, "label": "「茶の湯」古今亭志ん生" }
              ]
            },
            {
              "label": "第3回落語研究会（昭和30年4月30日）",
              "children": [
                { "leaf": true, "label": "「百川」桂文楽" },
                { "leaf": true, "label": "「芝浜」古今亭志ん生" },
                { "leaf": true, "label": "「目黒のさんま」三遊亭圓生" },
                { "leaf": true, "label": "「鰍沢」春風亭柳好" },
                { "leaf": true, "label": "「牛ほめ」三遊亭圓馬" }
              ]
            },
            {
              "label": "第4回落語研究会（昭和30年10月22日）",
              "children": [
                { "leaf": true, "label": "「死神」古今亭志ん生" },
                { "leaf": true, "label": "「三年目」柳家小さん" },
                { "leaf": true, "label": "「強情灸」春風亭柳好" },
                { "leaf": true, "label": "「宿屋の仇討」三遊亭圓生" },
                { "leaf": true, "label": "「火事息子」古今亭志ん生" }
              ]
            },
            {
              "label": "第5回落語研究会（昭和31年4月13日）",
              "children": [
                { "leaf": true, "label": "「野ざらし」桂文楽" },
                { "leaf": true, "label": "「替り目」春風亭柳好" },
                { "leaf": true, "label": "「掛取万歳」柳家小さん" },
                { "leaf": true, "label": "「百年目」三遊亭圓馬" },
                { "leaf": true, "label": "「真景累ヶ淵」古今亭志ん生" }
              ]
            },
            {
              "label": "第6回落語研究会（昭和31年10月28日）",
              "children": [
                { "leaf": true, "label": "「饅頭こわい」春風亭柳好" },
                { "leaf": true, "label": "「へっつい幽霊」三遊亭圓生" },
                { "leaf": true, "label": "「抜け雀」柳家小さん" },
                { "leaf": true, "label": "「佐野山」三遊亭圓馬" },
                { "leaf": true, "label": "「笠碁」古今亭志ん生" }
              ]
            },
            {
              "label": "第7回落語研究会（昭和32年4月30日）",
              "children": [
                { "leaf": true, "label": "「らくだ」古今亭志ん生" },
                { "leaf": true, "label": "「五人廻し」柳家小さん" },
                { "leaf": true, "label": "「粗忽長屋」三遊亭圓生" },
                { "leaf": true, "label": "「愛宕山」三遊亭圓馬" },
                { "leaf": true, "label": "「佃祭」桂文楽" }
              ]
            },
            {
              "label": "第8回落語研究会（昭和32年11月10日）",
              "children": [
                { "leaf": true, "label": "「品川心中」古今亭志ん生" },
                { "leaf": true, "label": "「転失気」柳家小さん" },
                { "leaf": true, "label": "「反魂香」三遊亭圓馬" },
                { "leaf": true, "label": "「妾馬」三遊亭圓生" },
                { "leaf": true, "label": "「長命」桂文楽" }
              ]
            },
            {
              "label": "第9回落語研究会（昭和33年4月27日）",
              "children": [
                { "leaf": true, "label": "「不動坊」古今亭志ん生" },
                { "leaf": true, "label": "「長屋の花見」柳家小さん" },
                { "leaf": true, "label": "「藪入り」三遊亭圓生" },
                { "leaf": true, "label": "「松曳き」春風亭柳好" },
                { "leaf": true, "label": "「猫久」三遊亭圓馬" }
              ]
            },
            {
              "label": "第10回落語研究会（昭和33年10月16日）",
              "children": [
                { "leaf": true, "label": "「しわいや」桂文楽" },
                { "leaf": true, "label": "「宿屋の仇討」三遊亭圓馬" },
                { "leaf": true, "label": "「寝床」柳家小さん" },
                { "leaf": true, "label": "「三軒長屋」古今亭志ん生" },
                { "leaf": true, "label": "「唐茄子屋政談」三遊亭圓生" }
              ]
            },
            {
              "label": "第11回落語研究会（昭和34年4月15日）",
              "children": [
                { "leaf": true, "label": "「子別れ」古今亭志ん生" },
                { "leaf": true, "label": "「初天神」柳家小さん" },
                { "leaf": true, "label": "「目黒のさんま」三遊亭圓馬" },
                { "leaf": true, "label": "「禁酒番屋」春風亭柳好" },
                { "leaf": true, "label": "「文七元結」三遊亭圓生" }
              ]
            },
            {
              "label": "第12回落語研究会（昭和34年11月3日）",
              "children": [
                { "leaf": true, "label": "「牛ほめ」桂文楽" },
                { "leaf": true, "label": "「藪入り」柳家小さん" },
                { "leaf": true, "label": "「火焔太鼓」三遊亭圓馬" },
                { "leaf": true, "label": "「初天神」古今亭志ん生" },
                { "leaf": true, "label": "「三軒長屋」三遊亭圓生" }
              ]
            }
          ]
        },


        {
          label: '第21回～第30回：1968(昭和43)年～1974(昭和49)年',
          children: [
            {
              label: '第21回わせだ寄席(昭和43年10月17日、大隈講堂)',
              children: [
                { leaf: true, label: '「たぬき」 柳家さん八' },
                { leaf: true, label: '「蛙茶番」 三遊亭圓弥' },
                { leaf: true, label: '「曲芸」 東富士夫' },
                { leaf: true, label: '「蒟蒻問答」 春風亭柳朝' },
                { leaf: true, label: '「野ざらし」 三遊亭圓楽' },
                { leaf: true, label: '「二番煎じ」 蝶花楼馬楽' },
                { leaf: true, label: '「明烏」 金原亭馬生' }
              ]
            },
            {
              label: '第22回春季わせだ寄席(昭和45年6月2日、厚生年金会館小ホール)',
              children: [
                { leaf: true, label: '「やかん泥」 三遊亭圓弥' },
                { leaf: true, label: '「千早ふる」 三遊亭圓楽' },
                { leaf: true, label: '「たがや」 金原亭馬生' },
                { leaf: true, label: '「木乃伊取り」 三遊亭圓生' },
                { leaf: true, label: '「首提灯」 林家正蔵' },
                { leaf: true, label: '「ちりとてちん」 柳家小さん' },
                { leaf: true, label: '「三井の大黒」 入船亭扇橋' }
              ]
            },
            {
              label: '第23回秋季わせだ寄席(昭和45年10月7日、渋谷児童会館)',
              children: [
                { leaf: true, label: '「浮世床」 三遊亭圓弥' },
                { leaf: true, label: '「三人旅」 柳家小さん' },
                { leaf: true, label: '「火事息子」 林家正蔵' },
                { leaf: true, label: '「蛙茶番」 春風亭柳朝' },
                { leaf: true, label: '「文違い」 三遊亭圓生' },
                { leaf: true, label: '「小言幸兵衛」 金原亭馬の助' }
              ]
            },
            {
              label: '第24回春季わせだ寄席(昭和46年5月26日、厚生年金会館小ホール)',
              children: [
                { leaf: true, label: '「長短」 林家三蔵' },
                { leaf: true, label: '「干物箱」 金原亭馬生' },
                { leaf: true, label: '「百川」 三遊亭圓生' },
                { leaf: true, label: '「愛宕山」 桂小南' },
                { leaf: true, label: '「五月幟」 三遊亭圓窓' },
                { leaf: true, label: '「中村仲蔵」 林家正蔵' }
              ]
            },
            {
              label: '第25回秋季わせだ寄席(昭和46年10月19日、日本青年館ホール)',
              children: [
                { leaf: true, label: '「七段目」 三遊亭圓弥' },
                { leaf: true, label: '「蜘蛛駕籠」 桂文朝' },
                { leaf: true, label: '「五人廻し」 三遊亭圓生' },
                { leaf: true, label: '「そば清」 金原亭馬生' },
                { leaf: true, label: '「唖の釣」 林家正蔵' },
                { leaf: true, label: '「漫才」 春日三球・照代' },
                { leaf: true, label: '「にらみ返し」 柳家小さん' }
              ]
            },
            {
              label: '第26回春季わせだ寄席(昭和47年5月27日、厚生年金会館小ホール)',
              children: [
                { leaf: true, label: '「雑俳」 桂小勇' },
                { leaf: true, label: '「猫の災難」 柳家小さん' },
                { leaf: true, label: '「野ざらし」 柳家小三治' },
                { leaf: true, label: '「もうせん芝居」 林家正蔵' },
                { leaf: true, label: '「大山詣り」 三遊亭圓生' },
                { leaf: true, label: '「村正」 金原亭馬生' }
              ]
            },
            {
              label: '第27回秋季わせだ寄席(昭和47年11月13日、日本青年館ホール)',
              children: [
                { leaf: true, label: '「手紙無筆」 三遊亭さん弥' },
                { leaf: true, label: '「火焔太鼓」 春風亭柳朝' },
                { leaf: true, label: '「文七元結」 林家正蔵' },
                { leaf: true, label: '「湯屋番」 柳家小さん' },
                { leaf: true, label: '「阿武松」 三遊亭圓生' },
                { leaf: true, label: '「猫忠」 三遊亭圓彌' }
              ]
            },
            {
              label: '第28回春季わせだ寄席(昭和48年5月22日)',
              children: [
                { leaf: true, label: '「お血脈」 古今亭志ん駒' },
                { leaf: true, label: '「いかけや」 桂小南' },
                { leaf: true, label: '「笠と赤い風船」 林家正蔵' },
                { leaf: true, label: '「厩火事」 柳家小三治' },
                { leaf: true, label: '「杯の殿様」 三遊亭圓生' },
                { leaf: true, label: '「笠碁」 金原亭馬生' }
              ]
            },
            {
              label: '第29回秋季わせだ寄席(昭和48年10月22日、日本青年館ホール)',
              children: [
                { leaf: true, label: '「ん廻し」 三遊亭ぬう生' },
                { leaf: true, label: '「短命」 三遊亭圓窓' },
                { leaf: true, label: '「首提灯」 林家正蔵' },
                { leaf: true, label: '「明烏」 金原亭馬生' },
                { leaf: true, label: '「ねずみ」 春風亭柳朝' },
                { leaf: true, label: '「寝床」 金原亭馬の助' },
                { leaf: true, label: '「家見舞」 柳家小さん' }
              ]
            },
            {
              label: '第30回記念春季わせだ寄席(昭和49年5月19日、厚生年金会館小ホール)',
              children: [
                { leaf: true, label: '「強情灸」 三遊亭ゆう生' },
                { leaf: true, label: '「たぬき」 春風亭柳朝' },
                { leaf: true, label: '「一眼国」 林家正蔵' },
                { leaf: true, label: '「しびん」 古今亭志ん朝' },
                { leaf: true, label: '「棒鱈」 柳家小さん' },
                { leaf: true, label: '「唐茄子屋政談」 金原亭馬生' }
              ]
            }
          ]
        },
        {
          label: '第31回～第40回：1974(昭和49)年～1979(昭和54)年',
          children: [
            {
              label: '第31回秋季わせだ寄席(昭和49年10月17日、日本青年館ホール)',
              children: [
                { leaf: true, label: '「悋気の火の玉」 桂小勇' },
                { leaf: true, label: '「池田大助」 桂文朝' },
                { leaf: true, label: '「心眼」 入船亭扇橋' },
                { leaf: true, label: '「紫檀楼古木」 林家正蔵' },
                { leaf: true, label: '「三井の大黒」 三遊亭圓生' },
                { leaf: true, label: '「厩火事」 柳家小三治' },
                { leaf: true, label: '「二番煎じ」 柳家小さん' }
              ]
            },
            {
              label: '第32回春季わせだ寄席(昭和50年6月6日、厚生年金会館小ホール)',
              children: [
                { leaf: true, label: '「初音の鼓」 林家正蔵' },
                { leaf: true, label: '「王子の狐」 柳家小さん' },
                { leaf: true, label: '「片棒」 桂文楽' },
                { leaf: true, label: '「鰍沢」 金原亭馬生' },
                { leaf: true, label: '「夢金」 三遊亭圓生' },
                { leaf: true, label: '「井戸の茶碗」 柳家小三治' }
              ]
            },
            {
              label: '第33回秋季わせだ寄席(昭和50年10月7日、日本青年館ホール)',
              children: [
                { leaf: true, label: '「まんじゅうこわい」 林家正蔵' },
                { leaf: true, label: '「千両幟」 古今亭志ん朝' },
                { leaf: true, label: '「黄金餅」 柳家小さん' },
                { leaf: true, label: '「唐茄子屋政談」 金原亭馬生' },
                { leaf: true, label: '「うどん屋」 柳家小三治' },
                { leaf: true, label: '「井戸の茶碗」 三遊亭圓生' }
              ]
            },
            {
              label: '第34回春季わせだ寄席(昭和51年6月3日、厚生年金会館小ホール)',
              children: [
                { leaf: true, label: '「近日息子」 柳家小三治' },
                { leaf: true, label: '「宿屋の富」 三遊亭圓生' },
                { leaf: true, label: '「片棒」 古今亭志ん朝' },
                { leaf: true, label: '「付き馬」 金原亭馬生' },
                { leaf: true, label: '「替り目」 柳家小さん' },
                { leaf: true, label: '「井戸の茶碗」 春風亭柳朝' }
              ]
            },
            {
              label: '第35回秋季わせだ寄席(昭和51年10月14日、日本青年館ホール)',
              children: [
                { leaf: true, label: '「田能久」 林家正蔵' },
                { leaf: true, label: '「寝床」 三遊亭圓生' },
                { leaf: true, label: '「粗忽の釘」 古今亭志ん朝' },
                { leaf: true, label: '「うどん屋」 金原亭馬生' },
                { leaf: true, label: '「らくだ」 柳家小三治' },
                { leaf: true, label: '「文七元結」 柳家小さん' }
              ]
            },
            {
              label: '第36回春季わせだ寄席(昭和52年6月9日、厚生年金会館小ホール)',
              children: [
                { leaf: true, label: '「不動坊」 柳家さん吉' },
                { leaf: true, label: '「五人廻し」 柳家小三治' },
                { leaf: true, label: '「鰍沢」 三遊亭圓生' },
                { leaf: true, label: '「船徳」 古今亭志ん朝' },
                { leaf: true, label: '「紺屋高尾」 金原亭馬生' },
                { leaf: true, label: '「宿屋の仇討」 柳家小さん' }
              ]
            },
            {
              label: '第37回秋季わせだ寄席(昭和52年10月26日、日本青年館ホール)',
              children: [
                { leaf: true, label: '「子ほめ」 柳家さん吉' },
                { leaf: true, label: '「笠と赤い風船」 林家正蔵' },
                { leaf: true, label: '「御神酒徳利」 金原亭馬生' },
                { leaf: true, label: '「三十石」 柳家小三治' },
                { leaf: true, label: '「短命」 三遊亭圓生' },
                { leaf: true, label: '「小間物屋政談」 古今亭志ん朝' },
                { leaf: true, label: '「五人廻し」 柳家小さん' }
              ]
            },
            {
              label: '第38回春季わせだ寄席(昭和53年6月6日、厚生年金会館小ホール)',
              children: [
                { leaf: true, label: '「小言幸兵衛」 柳家さん吉' },
                { leaf: true, label: '「火焔太鼓」 柳家小三治' },
                { leaf: true, label: '「帯久」 金原亭馬生' },
                { leaf: true, label: '「鼠穴」 古今亭志ん朝' },
                { leaf: true, label: '「紺屋高尾」 三遊亭圓生' },
                { leaf: true, label: '「付き馬」 柳家小さん' }
              ]
            },
            {
              label: '第39回秋季わせだ寄席(昭和53年10月28日、日本青年館ホール)',
              children: [
                { leaf: true, label: '「あくび指南」 柳家さん吉' },
                { leaf: true, label: '「そば清」 柳家小三治' },
                { leaf: true, label: '「百年目」 三遊亭圓生' },
                { leaf: true, label: '「心眼」 古今亭志ん朝' },
                { leaf: true, label: '「三十石」 金原亭馬生' },
                { leaf: true, label: '「文違い」 柳家小さん' }
              ]
            },
            {
              label: '第40回春季わせだ寄席(昭和54年6月3日、厚生年金会館小ホール)',
              children: [
                { leaf: true, label: '「親子酒」 柳家さん吉' },
                { leaf: true, label: '「佃祭」 金原亭馬生' },
                { leaf: true, label: '「権助提灯」 三遊亭圓生' },
                { leaf: true, label: '「鼠穴」 古今亭志ん朝' },
                { leaf: true, label: '「道具屋」 柳家小三治' },
                { leaf: true, label: '「田能久」 柳家小さん' }
              ]
            }
          ]
        },

        {
          "label": "第41回～第50回：1979（昭和54）年～1984（昭和59）年",
          "children": [
            {
              "label": "第41回秋季わせだ寄席（昭和54年11月21日、九段会館ホール）",
              "children": [
                { "leaf": true, "label": "「黄金の大黒」 五街道雲助" },
                { "leaf": true, "label": "「唖の釣」 桂文朝" },
                { "leaf": true, "label": "「二番煎じ」 古今亭志ん朝" },
                { "leaf": true, "label": "「粗忽の釘」 春風亭柳朝" },
                { "leaf": true, "label": "「蜘蛛駕籠」 柳家小さん" },
                { "leaf": true, "label": "「文七元結」 金原亭馬生" }
              ]
            },
            {
              "label": "第42回春季わせだ寄席（昭和55年6月25日、九段会館ホール）",
              "children": [
                { "leaf": true, "label": "「兵庫船」 林家正雀" },
                { "leaf": true, "label": "「代書屋」 桂小南" },
                { "leaf": true, "label": "「町内の若い衆」 三遊亭圓楽" },
                { "leaf": true, "label": "「小言幸兵衛」 春風亭柳朝" },
                { "leaf": true, "label": "「稽古屋」 春風亭小朝" },
                { "leaf": true, "label": "「道具屋」 月の家圓鏡" },
                { "leaf": true, "label": "「口上」 馬生・圓鏡・柳朝・小朝" },
                { "leaf": true, "label": "「青菜」 金原亭馬生" },
                { "leaf": true, "label": "「明烏」 春風亭小朝" }
              ]
            },
            {
              "label": "第43回わせだ寄席（昭和55年10月22日、九段会館ホール）",
              "children": [
                { "leaf": true, "label": "「強情灸」 古今亭朝太" },
                { "leaf": true, "label": "「時そば」 三遊亭圓橘" },
                { "leaf": true, "label": "「お花半七」 春風亭小朝" },
                { "leaf": true, "label": "「禁酒番屋」 桂文朝" },
                { "leaf": true, "label": "「国際噺家戦略」 三遊亭圓丈" },
                { "leaf": true, "label": "「死ぬなら今」 金原亭馬生" },
                { "leaf": true, "label": "「芝浜」 三遊亭圓楽" }
              ]
            },
            {
              "label": "第44回春季わせだ寄席（昭和56年5月19日、九段会館ホール）",
              "children": [
                { "leaf": true, "label": "「弥次郎」 入船亭扇好" },
                { "leaf": true, "label": "「野ざらし」 柳家さん喬" },
                { "leaf": true, "label": "「ねずみ穴」 月の家圓鏡" },
                { "leaf": true, "label": "「錦の袈裟」 春風亭柳朝" },
                { "leaf": true, "label": "「厩火事」 金原亭馬生" },
                { "leaf": true, "label": "「らくだ」 柳家小さん" }
              ]
            },
            {
              "label": "第45回秋季わせだ寄席（昭和56年10月16日、九段会館ホール）",
              "children": [
                { "leaf": true, "label": "「寄合酒」 春風亭正朝" },
                { "leaf": true, "label": "「船徳」 三遊亭楽太郎" },
                { "leaf": true, "label": "「紺屋高尾」 春風亭小朝" },
                { "leaf": true, "label": "「火焔太鼓」 月の家圓鏡" },
                { "leaf": true, "label": "「源平盛衰記」 桂文治" },
                { "leaf": true, "label": "「コント」 ラビット関根・小堺一機" },
                { "leaf": true, "label": "「自殺便り」 三遊亭圓丈" }
              ]
            },
            {
              "label": "第46回春季わせだ寄席（昭和57年5月19日、九段会館ホール）",
              "children": [
                { "leaf": true, "label": "「人形買い」 柳家さん光" },
                { "leaf": true, "label": "「黄金餅」 五街道雲助" },
                { "leaf": true, "label": "「浮世床」 露の五郎" },
                { "leaf": true, "label": "「大山詣り」 金原亭馬生" },
                { "leaf": true, "label": "「汲みたて」 三遊亭圓楽" },
                { "leaf": true, "label": "「藪入り」 柳家小三治" }
              ]
            },
            {
              "label": "第47回秋季わせだ寄席（昭和57年10月12日、九段会館ホール）",
              "children": [
                { "leaf": true, "label": "「星の王子様」 林家しん平" },
                { "leaf": true, "label": "「風呂敷」 古今亭朝太" },
                { "leaf": true, "label": "「大工調べ」 三遊亭小遊三" },
                { "leaf": true, "label": "「堪忍袋」 桂文朝" },
                { "leaf": true, "label": "「さんま火事」 入船亭扇橋" },
                { "leaf": true, "label": "「明烏」 古今亭志ん朝" }
              ]
            },
            {
              "label": "第48回春季わせだ寄席（昭和58年5月17日、日本教育会館）",
              "children": [
                { "leaf": true, "label": "「子ほめ」 柳家小幸" },
                { "leaf": true, "label": "「昔々の物語」 柳家小ゑん" },
                { "leaf": true, "label": "「身投げ屋」 五街道雲助" },
                { "leaf": true, "label": "「妾馬」 古今亭志ん橋" },
                { "leaf": true, "label": "「へっつい幽霊」 古今亭志ん朝" },
                { "leaf": true, "label": "「猫忠」 立川談生" },
                { "leaf": true, "label": "「ぞろぞろ」 立川談志" }
              ]
            },
            {
              "label": "第49回秋季わせだ寄席（昭和58年10月26日、一ツ橋ホール）",
              "children": [
                { "leaf": true, "label": "「日和違い」 三遊亭ちょび安" },
                { "leaf": true, "label": "「眼の合」 古今亭志ん朝" },
                { "leaf": true, "label": "「縁談」 三遊亭小遊三" },
                { "leaf": true, "label": "「猫の子羊」 金原亭馬生" },
                { "leaf": true, "label": "「一分間」 柳家小三治" },
                { "leaf": true, "label": "「梅雨茶屋」 月の家圓鏡" }
              ]
            },
            {
              "label": "第50回春季わせだ寄席（昭和59年6月8日、一ツ橋ホール）",
              "children": [
                { "leaf": true, "label": "「抜け穴」 三遊亭圓朝" },
                { "leaf": true, "label": "「白糸」 古今亭志ん橋" },
                { "leaf": true, "label": "「走れメロス」 三遊亭小遊三" },
                { "leaf": true, "label": "「酔いとし酔いと」 金原亭馬生" },
                { "leaf": true, "label": "「駄話」 五街道雲助" },
                { "leaf": true, "label": "「日記」 春風亭小朝" }
              ]
            }
          ]
        },

        {
          "label": "第61回～第70回：1992（平成04）年～2000（平成12）年",
          "children": [
            {
              "label": "第51回秋季わせだ寄席（昭和59年11月13日、日本教育会館）",
              "children": [
                { "leaf": true, "label": "「真田小僧」 古今亭志ん吉" },
                { "leaf": true, "label": "「代書屋」 柳家小八" },
                { "leaf": true, "label": "「そば清」 月の家靖鏡" },
                { "leaf": true, "label": "「粗忽の使者」 三遊亭小遊三" },
                { "leaf": true, "label": "「三枚起請」 古今亭朝太" },
                { "leaf": true, "label": "「大神楽」 鏡味仙之助・仙三郎" },
                { "leaf": true, "label": "「夢金」 五街道雲助" }
              ]
            },
            {
              "label": "第52回春季わせだ寄席（昭和60年5月13日、日本教育会館）",
              "children": [
                { "leaf": true, "label": "「道灌」 春風亭あさり" },
                { "leaf": true, "label": "「紙入れ」 金原亭馬治" },
                { "leaf": true, "label": "「壷算」 柳家権太楼" },
                { "leaf": true, "label": "「一人酒盛」 三遊亭鳳楽" },
                { "leaf": true, "label": "「宿屋の富」 三遊亭南喬" },
                { "leaf": true, "label": "「たがや」 春風亭小朝" }
              ]
            },
            {
              "label": "第53回秋季わせだ寄席（昭和60年10月21日、日本教育会館）",
              "children": [
                { "leaf": true, "label": "「堀之内」 柳家つむ治" },
                { "leaf": true, "label": "「唖の釣」 春風亭正朝" },
                { "leaf": true, "label": "「茶の湯」 桂文朝" },
                { "leaf": true, "label": "「小間物屋政談」 三遊亭圓楽" },
                { "leaf": true, "label": "「疝気の虫」 入船亭扇橋" },
                { "leaf": true, "label": "「太神楽」 鏡味仙之助・仙三郎" },
                { "leaf": true, "label": "「五人廻し」 柳家小三治" }
              ]
            },
            {
              "label": "第54回わせだ寄席（昭和61年5月26日、日本教育会館一ツ橋ホール）",
              "children": [
                { "leaf": true, "label": "「みかんや」 桂喜丸" },
                { "leaf": true, "label": "「天災」 桂朝丸" },
                { "leaf": true, "label": "「ちしゃ医者」 桂枝雀" },
                { "leaf": true, "label": "「崇徳院」 桂朝丸" },
                { "leaf": true, "label": "「更屋敷」 桂枝雀" }
              ]
            },
            {
              "label": "第55回わせだ寄席（昭和61年10月20日、日本教育会館一ツ橋ホール）",
              "children": [
                { "leaf": true, "label": "「たらちね」 柳家九治" },
                { "leaf": true, "label": "「面影の唄」 三遊亭きん歌" },
                { "leaf": true, "label": "「片棒」 柳家さん喬" },
                { "leaf": true, "label": "「へっつい幽霊」 入船亭扇橋" },
                { "leaf": true, "label": "「お見立て」 三遊亭圓彌" },
                { "leaf": true, "label": "「藪入り」 柳家小三治" }
              ]
            },
            {
              "label": "第56回わせだ寄席（昭和62年6月4日、日経ホール）",
              "children": [
                { "leaf": true, "label": "「二十四考」 柳家小のり" },
                { "leaf": true, "label": "「初音の鼓」 立川志の輔" },
                { "leaf": true, "label": "「野ざらし」 古今亭志ん橋" },
                { "leaf": true, "label": "「蒟蒻問答」 柳家小三治" },
                { "leaf": true, "label": "「浮世床」 三遊亭小遊三" },
                { "leaf": true, "label": "「試し酒」 柳家小さん" }
              ]
            },
            {
              "label": "第57回わせだ寄席（昭和63年11月15日、九段会館ホール）",
              "children": [
                { "leaf": true, "label": "「元犬」 入船亭扇べい" },
                { "leaf": true, "label": "「厄払い」 柳家さん好" },
                { "leaf": true, "label": "「悋気の火の玉」 春風亭正朝" },
                { "leaf": true, "label": "「長者番付」 柳家小三治" },
                { "leaf": true, "label": "「転宅」 柳家さん喬" },
                { "leaf": true, "label": "「紙切り」 林家小正楽" },
                { "leaf": true, "label": "「茶金」 古今亭志ん朝" }
              ]
            },
            {
              "label": "第58回わせだ寄席（平成1年10月16日、日本教育会館一ツ橋ホール）",
              "children": [
                { "leaf": true, "label": "「壷算」 立川志の輔" },
                { "leaf": true, "label": "「錦の袈裟」 三遊亭小遊三" },
                { "leaf": true, "label": "「遠山政談」 桂文朝" },
                { "leaf": true, "label": "「駒長」 古今亭志ん輔" },
                { "leaf": true, "label": "「太神楽」 鏡味仙之助・仙三郎" },
                { "leaf": true, "label": "「うどん屋」 柳家小さん" }
              ]
            },
            {
              "label": "第59回わせだ寄席（平成2年11月29日、千代田区公会堂）",
              "children": [
                { "leaf": true, "label": "「狸の賽」 桂米裕" },
                { "leaf": true, "label": "「稽古屋」 桂小米朝" },
                { "leaf": true, "label": "「天狗裁き」 桂米朝" },
                { "leaf": true, "label": "「崇徳院」 桂小米朝" },
                { "leaf": true, "label": "「鯉舟」 桂米朝" }
              ]
            },
            {
              "label": "第60回わせだ寄席（平成3年10月16日、日本教育会館一ツ橋ホール）",
              "children": [
                { "leaf": true, "label": "「片棒」 柳家三三" },
                { "leaf": true, "label": "「休養者」 三遊亭小圓朝" },
                { "leaf": true, "label": "「夢幻庵」 柳家小三治" },
                { "leaf": true, "label": "「仏師」 春風亭小朝" },
                { "leaf": true, "label": "「年賀」 柳家小三治" }
              ]
            }
          ]
        },

        {
          "label": "第61回～第70回：1992（平成04）年～2000（平成12）年",
          "children": [
            {
              "label": "第61回わせだ寄席（平成4年11月25日、日本教育会館一ツ橋ホール）",
              "children": [
                { "leaf": true, "label": "「鮑のし」 林家きく兵衛" },
                { "leaf": true, "label": "「初天神」 柳家さん好" },
                { "leaf": true, "label": "「平の陰」 笑福亭鶴志" },
                { "leaf": true, "label": "「妾馬」 五街道雲助" },
                { "leaf": true, "label": "「代書屋」 柳家権太楼" },
                { "leaf": true, "label": "「鼻ほしい」 桂文治" }
              ]
            },
            {
              "label": "第62回わせだ寄席（平成5年12月1日、なかのZEROホール）",
              "children": [
                { "leaf": true, "label": "「一目上がり」 三遊亭金太" },
                { "leaf": true, "label": "「すみれ荘201号」 柳家喬太郎" },
                { "leaf": true, "label": "「町内の若い衆」 柳家小せん" },
                { "leaf": true, "label": "「禁酒番屋」 柳家小さん" },
                { "leaf": true, "label": "「中沢家の人々」 三遊亭圓歌" },
                { "leaf": true, "label": "「尻餅」 柳亭市馬" }
              ]
            },
            {
              "label": "第63回わせだ寄席（平成6年11月16日、かんだパンセホール）",
              "children": [
                { "leaf": true, "label": "「堀の内」 わたし" },
                { "leaf": true, "label": "「たらちね」 林家たい平" },
                { "leaf": true, "label": "「看板のピン」 柳家小三治" },
                { "leaf": true, "label": "「中沢家の人々」 三遊亭圓歌" },
                { "leaf": true, "label": "「座談会」 興津要" },
                { "leaf": true, "label": "「柳家小さん」" },
                { "leaf": true, "label": "「三遊亭圓歌」" },
                { "leaf": true, "label": "「奇術」 松旭斎すみえ他二名" },
                { "leaf": true, "label": "「千早ふる」 柳家小さん" }
              ]
            },
            {
              "label": "第64回わせだ寄席（平成7年12月11日、日本教育会館一ツ橋ホール）",
              "children": [
                { "leaf": true, "label": "「ざる屋」 五街道喜助" },
                { "leaf": true, "label": "「純情日記 横浜編」 柳家喬太郎" },
                { "leaf": true, "label": "「やかんなめ」 柳家喜多八" },
                { "leaf": true, "label": "「五貫裁き」 立川志の輔" },
                { "leaf": true, "label": "「愛犬チャッピー」 春風亭昇太" },
                { "leaf": true, "label": "「漫才」 爆笑問題" },
                { "leaf": true, "label": "「三十石」 桂文我" }
              ]
            },
            {
              "label": "第65回わせだ寄席（平成8年11月16日、曳舟文化センター大ホール）",
              "children": [
                { "leaf": true, "label": "「強情灸」 横目家助平" },
                { "leaf": true, "label": "「小言念仏」 柳家喜多八" },
                { "leaf": true, "label": "「僕の一人コント集」 モロ師岡" },
                { "leaf": true, "label": "「結婚相談所」 昔々亭桃太郎" },
                { "leaf": true, "label": "「野ざらし」 立川藤志楼" },
                { "leaf": true, "label": "「ガーコン」 川柳川柳" },
                { "leaf": true, "label": "「らくだ」 笑福亭松之助" }
              ]
            },
            {
              "label": "第66回わせだ寄席（平成9年6月17日、新宿朝日生命ホール）",
              "children": [
                { "leaf": true, "label": "「お菊の皿」 春風亭あさ市" },
                { "leaf": true, "label": "「衆論」 春風亭正朝" },
                { "leaf": true, "label": "「紀州」 春風亭小朝" },
                { "leaf": true, "label": "「松竹梅」 林家木久蔵" },
                { "leaf": true, "label": "「ギター漫談」 林家ぺー" },
                { "leaf": true, "label": "「かんしゃく」 橘家圓太郎" }
              ]
            },
            {
              "label": "第67回わせだ寄席（平成9年12月10日）",
              "children": [
                { "leaf": true, "label": "「子ほめ」 立川志の吉" },
                { "leaf": true, "label": "「くしゃみ講釈」 立川談坊" },
                { "leaf": true, "label": "「お血脈」 立川志の輔" },
                { "leaf": true, "label": "「ロック忠臣蔵」 国本武春" },
                { "leaf": true, "label": "「ねずみ」 立川志の輔" }
              ]
            },
            {
              "label": "第68回わせだ寄席（平成10年10月17日、大隈小講堂）",
              "children": [
                { "leaf": true, "label": "「転失気」 古今亭菊一" },
                { "leaf": true, "label": "「壷算」 柳家さん光" },
                { "leaf": true, "label": "「反対俥」 林家たい平" },
                { "leaf": true, "label": "「お見立て」 五街道喜助" },
                { "leaf": true, "label": "「レモン」 神田北陽" },
                { "leaf": true, "label": "「茶の湯」 柳亭燕路" }
              ]
            },
            {
              "label": "第69回わせだ寄席（平成11年5月15日、大隈小講堂）",
              "children": [
                { "leaf": true, "label": "「転失気」 柳家小ざる" },
                { "leaf": true, "label": "「家見舞」 柳家さん光" },
                { "leaf": true, "label": "「あくび指南」 柳家喜多八" },
                { "leaf": true, "label": "「片棒」 柳亭燕路" },
                { "leaf": true, "label": "「小言幸兵衛」 柳家小三治" }
              ]
            },
            {
              "label": "第70回わせだ寄席（平成12年11月30日、大隈大講堂）",
              "children": [
                { "leaf": true, "label": "「HOW TOプレイボーイ」 桂三若" },
                { "leaf": true, "label": "「ダンシングドクター」 桂三枝" },
                { "leaf": true, "label": "「神様のご臨終」 桂三枝" },
                { "leaf": true, "label": "「インタビュー＆トークショー」 桂三枝" },
                { "leaf": true, "label": "「渡辺信義」" },
                { "leaf": true, "label": "「桂三若」" },
                { "leaf": true, "label": "「安田瞳」" }
              ]
            }
          ]
        },

        {
          "label": "第71回～第80回：2001（平成13）年～2010（平成22）年",
          "children": [
            {
              "label": "第71回わせだ寄席（平成13年5月18日、大隈小講堂）",
              "children": [
                { "leaf": true, "label": "「出来心」 柳家初花" },
                { "leaf": true, "label": "「松山鏡」 国本武春" },
                { "leaf": true, "label": "「七段目」 林家たい平" },
                { "leaf": true, "label": "「紙切り」 林家正楽" },
                { "leaf": true, "label": "「粗忽の釘」 柳家喜多八" }
              ]
            },
            {
              "label": "第72回わせだ寄席（平成14年11月30日、大隈小講堂）",
              "children": [
                { "leaf": true, "label": "「熊の皮」 柳家三太楼" },
                { "leaf": true, "label": "「派出所ビーナス」 柳家喬太郎" },
                { "leaf": true, "label": "「地球の栓」 立川志らく" },
                { "leaf": true, "label": "「紙切り」 神田山陽" },
                { "leaf": true, "label": "「曲独楽」 三増紋也・れ紋" },
                { "leaf": true, "label": "「幽霊の辻」 柳家権太楼" }
              ]
            },
            {
              "label": "第73回わせだ寄席（平成15年12月6日、大隈小講堂）",
              "children": [
                { "leaf": true, "label": "「道灌」 柳家三三" },
                { "leaf": true, "label": "「紙屑屋」 林家たい平" },
                { "leaf": true, "label": "「二番煎じ」 古今亭志ん輔" },
                { "leaf": true, "label": "「壺算」 春風亭昇太" },
                { "leaf": true, "label": "「漫才」 昭和のいる・こいる" },
                { "leaf": true, "label": "「紺屋高尾」 柳家花緑" }
              ]
            },
            {
              "label": "第74回わせだ寄席（平成16年11月26日、大隈小講堂）",
              "children": [
                { "leaf": true, "label": "「反対俥」 立川笑志" },
                { "leaf": true, "label": "「午後の保健室」 柳家喬太郎" },
                { "leaf": true, "label": "「五人廻し」 柳家喜多八" },
                { "leaf": true, "label": "「紙入れ」 立川談春" },
                { "leaf": true, "label": "「曲独楽」 三増紋之助" },
                { "leaf": true, "label": "「青木先生」 笑福亭鶴瓶" }
              ]
            },
            {
              "label": "第75回わせだ寄席（平成17年11月17日、大隈小講堂）",
              "children": [
                { "leaf": true, "label": "「代脈」 桃月庵白酒" },
                { "leaf": true, "label": "「初天神」 柳家三太楼" },
                { "leaf": true, "label": "「火焔太鼓」 立川志らく" },
                { "leaf": true, "label": "「愛宕山」 林家たい平" },
                { "leaf": true, "label": "「大神楽」 柳貴家小雪" },
                { "leaf": true, "label": "「人生が二度あれば」 春風亭昇太" }
              ]
            },
            {
              "label": "第76回わせだ寄席（平成18年11月1日、井深大記念ホール）",
              "children": [
                { "leaf": true, "label": "「牛ほめ」 立川志の吉" },
                { "leaf": true, "label": "「転宅」 立川笑志" },
                { "leaf": true, "label": "「犬の目」 立川文都" },
                { "leaf": true, "label": "「奇術」 伊藤夢葉" },
                { "leaf": true, "label": "「親の顔」 立川志の輔" }
              ]
            },
            {
              "label": "第77回わせだ寄席（平成19年10月31日、井深大記念ホール）",
              "children": [
                { "leaf": true, "label": "「六尺棒」 三遊亭好二郎" },
                { "leaf": true, "label": "「権助提灯」 柳家三三" },
                { "leaf": true, "label": "「鼠小僧外伝」 神田山陽" },
                { "leaf": true, "label": "「お見合い中」 昔昔亭桃太郎" },
                { "leaf": true, "label": "「片棒」 柳亭市馬" },
                { "leaf": true, "label": "「死神」 笑福亭鶴瓶" }
              ]
            },
            {
              "label": "第78回わせだ寄席（平成20年10月24日、井深大記念ホール）",
              "children": [
                { "leaf": true, "label": "「居酒屋・改」 立川談笑" },
                { "leaf": true, "label": "「堪忍袋」 三遊亭遊雀" },
                { "leaf": true, "label": "「千早振る」 滝川鯉昇" },
                { "leaf": true, "label": "「お見立て」 林家たい平" },
                { "leaf": true, "label": "「太神楽」 鏡味仙三" },
                { "leaf": true, "label": "「紺屋高尾」 立川談春" }
              ]
            },
            {
              "label": "第79回わせだ寄席（平成21年12月15日、大隈大講堂）",
              "children": [
                { "leaf": true, "label": "「擬宝珠」 柳家喬太郎" },
                { "leaf": true, "label": "「替り目」 桃月庵白酒" },
                { "leaf": true, "label": "「明烏」 柳家喜多八" },
                { "leaf": true, "label": "「紙切り」 林家正楽" },
                { "leaf": true, "label": "「寝床」 春風亭昇太" }
              ]
            },
            {
              "label": "第80回わせだ寄席（平成22年10月29日、井深大記念ホール）",
              "children": [
                { "leaf": true, "label": "「鈴ヶ森」 春風亭一之輔" },
                { "leaf": true, "label": "「時そば」 立川談笑" },
                { "leaf": true, "label": "「七段目」 柳亭市馬" },
                { "leaf": true, "label": "「太神楽」 翁家勝丸" },
                { "leaf": true, "label": "「文七元結」 立川志らく" }
              ]
            }
          ]
        },
        {
          "label": "第81回～第90回：2011（平成23）年～2020（令和2）年",
          "children": [
            {
              "label": "第81回わせだ寄席（平成23年12月1日、大隈大講堂）",
              "children": [
                { "leaf": true, "label": "「他行」 三遊亭夢吉" },
                { "leaf": true, "label": "「粗忽長屋」 三遊亭兼好" },
                { "leaf": true, "label": "「短命」 橘屋圓太郎" },
                { "leaf": true, "label": "「俗曲」 柳家柴文" },
                { "leaf": true, "label": "「ALWAYSお母ちゃんの笑顔」 笑福亭鶴瓶" }
              ]
            },
            {
              "label": "第82回わせだ寄席（平成24年11月27日、大隈小講堂）",
              "children": [
                { "leaf": true, "label": "「寄合酒」 三遊亭きつつき" },
                { "leaf": true, "label": "「睨み合い」 林家彦いち" },
                { "leaf": true, "label": "「持参金」 瀧川鯉昇" },
                { "leaf": true, "label": "「奇術」 伊藤夢葉" },
                { "leaf": true, "label": "「ハワイの雪」 柳家喬太郎" }
              ]
            },
            {
              "label": "第83回わせだ寄席（平成25年11月28日、大隈小講堂）",
              "children": [
                { "leaf": true, "label": "「芝居の喧嘩」 柳家ろべえ" },
                { "leaf": true, "label": "「曲独楽」 三増紋之助" },
                { "leaf": true, "label": "「幇間腹」 桂南喬" },
                { "leaf": true, "label": "「露出さん」 春風亭百栄" },
                { "leaf": true, "label": "「だくだく」 柳家喜多八" }
              ]
            },
            {
              "label": "第84回わせだ寄席（平成26年12月11日、大隈小講堂）",
              "children": [
                { "leaf": true, "label": "「つる」 桂宮治" },
                { "leaf": true, "label": "「そば清」 古今亭文菊" },
                { "leaf": true, "label": "「松曳き」 桃月庵白酒" },
                { "leaf": true, "label": "「紙切り」 林家正楽" },
                { "leaf": true, "label": "「富久」 五街道雲助" }
              ]
            },
            {
              "label": "第85回わせだ寄席（平成27年12月3日、大隈小講堂）",
              "children": [
                { "leaf": true, "label": "「金明竹」 入船亭小辰" },
                { "leaf": true, "label": "「ふぐ鍋」 柳家小せん" },
                { "leaf": true, "label": "「背なで老いてる唐獅子牡丹」 柳家はん治" },
                { "leaf": true, "label": "「太神楽」 鏡味仙三郎社中" },
                { "leaf": true, "label": "「お見立て」 入船亭扇遊" }
              ]
            },
            {
              "label": "第86回わせだ寄席（平成28年12月14日、大隈小講堂）",
              "children": [
                { "leaf": true, "label": "「寄合酒」 三遊亭わん丈" },
                { "leaf": true, "label": "「宮戸川」 春風亭正朝" },
                { "leaf": true, "label": "「時そば」 柳家喬太郎" },
                { "leaf": true, "label": "「奇術」 ダーク広和" },
                { "leaf": true, "label": "「猫の災難」 柳家権太楼" }
              ]
            },
            {
              "label": "第87回わせだ寄席（平成29年11月22日、大隈小講堂）",
              "children": [
                { "leaf": true, "label": "「真田小僧」 柳亭市楽" },
                { "leaf": true, "label": "「効くやつください」 三遊亭天どん" },
                { "leaf": true, "label": "「天災」 春風亭一朝" },
                { "leaf": true, "label": "「粋曲」 柳家小菊" },
                { "leaf": true, "label": "「二番煎じ」 瀧川鯉昇" }
              ]
            },
            {
              "label": "第88回わせだ寄席（平成30年12月5日、大隈小講堂）",
              "children": [
                { "leaf": true, "label": "「子ほめ」 柳家小はぜ" },
                { "leaf": true, "label": "「持参金」 入船亭扇好" },
                { "leaf": true, "label": "「爆笑龍馬伝」 三遊亭歌之助" },
                { "leaf": true, "label": "「安兵衛狐」 金原亭馬生" },
                { "leaf": true, "label": "「奇術」 ナポレオンズ" },
                { "leaf": true, "label": "「五人廻し」 立川龍志" }
              ]
            },
            {
              "label": "第89回わせだ寄席（令和元年12月12日、小野梓記念講堂）",
              "children": [
                { "leaf": true, "label": "「酒粕」 金原亭乃々香" },
                { "leaf": true, "label": "「地べたの二人～おかず交換」 玉川太福" },
                { "leaf": true, "label": "「両泥」 柳亭小痴楽" },
                { "leaf": true, "label": "「七段目」 八光亭春輔" },
                { "leaf": true, "label": "「代書屋」 桂雀々" },
                { "leaf": true, "label": "「動物ものまね」 江戸家小猫" },
                { "leaf": true, "label": "「芝浜」 柳家さん喬" }
              ]
            },
            {
              "label": "第90回わせだ寄席（令和2年12月13日、お江戸日本橋亭）",
              "children": [
                { "leaf": true, "label": "林家彦いち独演会：コロナウイルスの影響を考慮して" },
                { "leaf": true, "label": "内部鑑賞会形式(独演会&お客様無し)で開催しました。" }
              ]
            }
          ]
        },
        {
          "label": "第91回～：2021（令和3）年～",
          "children": [
            {
              "label": "第91回わせだ寄席（令和3年12月4日、お江戸両国亭）",
              "children": [
                { "leaf": true, "label": "入船亭扇遊独演会：コロナウイルスの影響を考慮して" },
                { "leaf": true, "label": "内部鑑賞会形式(独演会&お客様無し)で開催しました。" }
              ]
            },
            {
              "label": "第92回わせだ寄席（令和4年12月21日、小野記念講堂）",
              "children": [
                { "leaf": true, "label": "「手水廻し」笑福亭茶光" },
                { "leaf": true, "label": "「稽古屋」柳亭こみち" },
                { "leaf": true, "label": "「たいこ腹」桂伸治" },
                { "leaf": true, "label": "紙切り 林家楽一" },
                { "leaf": true, "label": "「心眼」 古今亭文菊" }
              ]
            },
            {
              "label": "第93回わせだ寄席（令和5年12月20日、小野記念講堂）",
              "children": [
                { "leaf": true, "label": "「転失気」春風亭朝枝" },
                { "leaf": true, "label": "「殿様団子」三笑亭夢丸" },
                { "leaf": true, "label": "「半ソボン」林家きく麿" },
                { "leaf": true, "label": "浮世節 立花家橘之助" },
                { "leaf": true, "label": "赤穂義士外伝より「天野屋利兵衛」神田松鯉" }
              ]
            },
            {
              "label": "第94回わせだ寄席（令和6年11月22日、小野記念講堂）",
              "children": [
                { "leaf": true, "label": "「まんじゅうこわい」昔昔亭昇" },
                { "leaf": true, "label": "左甚五郎伝より「掛川宿」広沢菊春（曲師：広沢美舟）" },
                { "leaf": true, "label": "「厩火事」入船亭扇遊" },
                { "leaf": true, "label": "「夏どろ」春風亭一蔵" },
                { "leaf": true, "label": "曲独楽 三増家紋之助" },
                { "leaf": true, "label": "「めめめ」～「にきび」瀧川鯉八" }
              ]
            }
          ]
        }

      ]
    }
  }


}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.history {
  padding-bottom: 50px;
}

.class00 {
  margin-left: 50px;
  margin-right: 50px;
  color: rgba(97, 255, 242, 0.71);
  background-color: none;
}

@media screen and (max-width: 640px) {
  .class00 {
    margin-left: 5px;
    margin-right: 5px;
    font-size: small;
    overflow: scroll;
  }
}</style>
