// Vuexを実装するためのコード
// Vuexとは、コード全体を通して共有できる保管庫のようなもので、リロードされない限りHPのアクセス時から内容が保持される。
// らっけんHPでは、HPアクセス時のポップアップ表示を、最初のアクセス時のみに制限するために使用。
import { createStore } from 'vuex'

export default createStore({
  state: {
    popupShown: false
  },
  mutations: {
    setPopupShown(state, value) {
      state.popupShown = value
    }
  },
  actions: {
  },
  getters: {
  }
})
