import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import EventView from '../views/EventView.vue'
import ContactView from '../views/ContactView.vue'
import JoinusView from '../views/JoinusView.vue'
import ThisYear from '../views/Thisyear.vue'
import TempView from '../views/TempView.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/event',
    name: 'Event',
    component: EventView
  },
  {
    path: '/contact',
    name: 'Contact',
    component: ContactView
  },

  {
    path: '/joinus',
    name: 'Joinus',
    component: JoinusView
  },
  {
    path: '/thisyear',
    name: 'Thisyear',
    component: ThisYear
  },
  {
    path: '/temp',
    name: 'TempView',
    component: TempView
  },
  {
    path: '/:paths(.*)*',
    name: 'Fallback',
    redirect: '/'
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  //router遷移時にトップから表示させる。
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        top: 0
      }
    }
  },
})

export default router
