import { createApp } from 'vue'
import App from './App.vue'
import installElementPlus from './plugins/element'
import store from './store'
import router from './router'
import ElemetPlus from 'element-plus';
import 'element-plus/dist/index.css';
import './style.scss'

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faFacebook, faTwitter, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';

library.add(faFacebook, faTwitter, faInstagram, faYoutube);

//const app = createApp(App).use(router).use(store)
const app = createApp(App).use(router).use(store).use(ElemetPlus).component('font-awesome-icon', FontAwesomeIcon)
//ルータとVuexを有効化してappオブジェクトを生成

app.config.globalProperties.$http=(url, opts)=>fetch(url, opts)
//http経由でfetchを呼び出せるように、アプリ全体のメソッド（プロパティの名前は$http）としておく。

installElementPlus(app)
//ElementPlusを有効化

app.mount('#app')