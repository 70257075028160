<template>
  <div class="contact">
    <br>
    <h2>出演依頼</h2>
    <p>
      早稲田大学落語研究会では、勉強のために慰問公演を行っています。<br>
      過去には地域の催し、老人ホーム、宴会、福祉団体の催しなどに出演しています。
    </p>
  </div>

  <div class="contact_about">
    <h2>連絡について</h2>
    <p>慰問口演を希望される場合は、以下の情報を添えてご連絡下さい。</p>
    <ul class="yourinfo">
      <li>担当者の連絡先（電話番号、メールアドレスなど）</li>
      <li>口演場所</li>
      <li>日時</li>
      <li>観客人数（およそで結構です）</li>
    </ul>
    <p>
      ※慰問の際、演者数に比した交通費や食事代などの諸経費を頂戴しております。ご了承ください。<br>
      ※学業第一なので、日程の都合などでお断りすることがあります。予めご承知置き下さい。<br>
      ※なるべく早めにご連絡下さい。
    </p>
    <br>
    <h2>メール</h2>
    <p>ご連絡は以下のメールアドレスからお願いいたします。</p>
    <a href="mailto:waseda.rakken@gmail.com">waseda.rakken@gmail.com</a>
    <br><br>
    <h2>必要な物</h2>
    <ul class="weneed">
      <li>座布団</li>
      <li>着替える場所</li>
      <li>高座（机を合わせたものなどでも構いません）</li>
      <li>CDの再生機器（CDは持参します）</li>
      <li>マイク（広い会場の場合のみ）</li>
    </ul>
    <br><br>

    <!--過去の慰問風景をギャラリー表示するクラス-->
    <div class="garally">
      <h2>Image</h2>
      <ul>
        <li>
          <div class="garally_contents">
            <img src="../assets/img22.jpg">
            <p>「納涼会」富士見三芳稲門会様</p>
          </div>
        </li>
        <li>
          <div class="garally_contents">
            <img src="../assets/img23.jpg">
            <p>学校寄席</p>
          </div>
        </li>
        <li>
          <div class="garally_contents">
            <img src="../assets/img26.jpg">
            <p>地域寄席</p>
          </div>
        </li>
        <li>
          <div class="garally_contents">
            <img src="../assets/img24.jpg">
            <p>真剣に</p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped>
ul,
li {
  max-width: 80%;
}

.contact {
  text-align: left;
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 200px;
  color: var(--secondary-color);
  background-color: var(--primary-color);
  width: 100%;
  padding-bottom: 50px;
}

.contact::after {
  content: "";
  position: fixed;
  left: 0;
  top: 300px;
  width: 100%;
  height: 100%;
  background: url(../assets/img000.jpg) no-repeat center/cover;
  z-index: -1;
  opacity: 1;
  animation: fadeIn 3s forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.contact_about {
  padding: 20px;
  box-sizing: border-box;
  text-align: left;
  margin-bottom: 400px;
  color: var(--secondary-color);
  background-color: var(--third-color);
  width: 100%;
}

.yourinfo {
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  width: 400px;
  font-size: small;
}

.weneed {
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  width: 500px;
  font-size: small;
}

.garally {
  padding-bottom: 50px;
  margin-left: 10px;
  margin-right: 10px;
}

.garally ul {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
  justify-content: center;
  padding: 10px;
}

.garally ul li {
  width: auto;
  height: 300px;
  padding: 8px;
  display: block;
  margin: 15px;
}

.garally ul li img {
  width: auto;
  height: 250px;
}

.garally ul li p {
  width: auto;
  text-align: left;
  margin-top: 4px;
  position: relative;
}

.garally_contents {
  display: inline-block
}


@media screen and (max-width: 640px) {
  .contact {
    overflow-x: hidden;
  }


  .yourinfo {
    width: 350px;
  }

  .garally ul li img {
    width: auto;
    height: 160px;
  }

  .garally ul li {
    height: 260px;
  }

  .garally ul li img {
    height: 200px;
  }
}</style>