<template>
  <!--写真のカロセル表示、インパクト強めに大きく表示-->
  <div class="carousel">
    <el-carousel class="carousel_item" :interval="4000" arrow="always" indicator-position="outside" height="700px"
      :autoplay="true">
      <el-carousel-item v-for="(image, index) in images" :key="index">
        <img :src="image" alt="carousel image" class="carousel-image" />
      </el-carousel-item>
    </el-carousel>
  </div>

  <!--わせだ寄席のポップアップ宣伝用-->
  <!--
  <div v-if="!$store.state.popupShown" class="popup" :class="{ show: showPopup }">
    <p>お知らせ</p>
    <img class="leaflet" src="../assets/img30.jpg">
    <br>
    <router-link to="/thisyear" class="nav-link">→詳しくはこちら</router-link>
    <br>
    <button @click="closePopup">Close</button>
  </div>
-->

  <!--お知らせ機能をElementPlusのスクロールバーで実装。お知らせの中身は下のScript欄
    から。-->
  <div class="information">
    <h3>お知らせ</h3>
    <el-scrollbar height="170px">
      <p v-for="(announcement, index) in announcements" :key="index" class="scrollbar-demo-item">{{ announcement }}</p>
    </el-scrollbar>
  </div>

  <!--そのほかのHomeの中身-->
  <div class="home">
    <h3>早稲田大学落語研究会は、日本で最も古い歴史を持つ落語研究会です。</h3>
    <br><br>
    <p>当会では創立の1948年以来、落語の鑑賞を活動の中心としてきました。</p>
    <p>噺家さんをお招きしての「鑑賞会」を月一回の頻度で開催しています。</p>

    <h3>活動内容</h3>
    <ul>
      <li>落語の鑑賞会を隔月一回程度開催</li>
      <li>秋には大学の講堂で「わせだ寄席」を主催</li>
      <li>落語の実演活動も積極的に行っており、早稲田祭や各地での慰問、お店での催し等で発表しています。</li>
    </ul>

    <h3>落語の魅力</h3>
    <p>当会には、落語に初めて触れた人も多くいます。<br>
      落語は笑点のようなテレビ番組とは異なり、奥深い人間模様や風俗・習慣、ユーモアが満載です。</p>
    <p>「笑点」を知らないあなたも、落語に対する興味がいまいちなあなたも、是非一度、落語に触れてみませんか？</p>
    <br>

    <!--tempページに遷移するrouter機能-->
    <h4>早稲田祭企画について</h4>
    <router-link to="/temp" class="nav-link">Click here</router-link>
    <br><br>

    <!--Twitterのウィジェット-->
    <h4>活動情報</h4>
    <div class="twitter">
      <a class="twitter-timeline" data-width="80%" data-height="400"
        href="https://twitter.com/waseda_rakugo?ref_src=twsrc%5Etfw">Tweets by waseda_rakugo</a>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      showPopup: false,
      popupEndDate: new Date('2024-11-21T23:59:59'), // ポップアップ表示終了日
      images: [
        require('../assets/img17.jpg'),
        //require('../assets/img20.jpg'),
        require('../assets/img21.jpg'),
        require('../assets/img18.jpg'),
        require('../assets/img12.jpg'),
        require('../assets/img19.jpg'),
        require('../assets/photo2.jpg'),
        require('../assets/img01.jpg'),
        //require('../assets/photo3.jpg'),
        require('../assets/photo1.jpg'),
        require('../assets/photo4.jpg'),
        require('../assets/img15.jpg')
      ],
      announcements: [
        "今年度のわせだ寄席の情報を更新しました!!(2024/10)",
        "当会では引き続き新規入会を受け付けています！",
        "ホームページを大幅リニューアルしました！(2024/7)",
        "慰問公演に関する情報を更新しました！(2024/7)"
      ]
    };
  },
  mounted() {
    if(this.shouldShowPopup){
    setTimeout(() => {
      this.showPopup = true; // 1秒後にポップアップを表示
    }, 1000)} // 1000ミリ秒 (1秒) 後にポップアップを表示
    window.addEventListener('scroll', this.preventScrollUp);
    // Twitterウィジェットスクリプトがすでにロードされているか確認
    if (window.twttr) {
      window.twttr.widgets.load();
    } else {
      // スクリプトがロードされていない場合、新たにスクリプトを追加
      const script = document.createElement('script');
      script.setAttribute('src', 'https://platform.twitter.com/widgets.js');
      script.setAttribute('charset', 'utf-8');
      document.head.appendChild(script);
    }
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.preventScrollUp);
  },
  computed: {
    shouldShowPopup() {
      const now = new Date(); // 現在の日付
      return now <= this.popupEndDate; // 現在日付が範囲内か確認
    }
  },
  methods: {
    preventScrollUp() {
      if (window.scrollY < 0) {
        window.scrollTo(0, 0);
      }
    },
    closePopup() {
      // popupを閉じる際に、Vuexのミューテーションを直接呼び出して popupShown を true に設定
      this.$store.commit('setPopupShown', true)
      this.showPopup = false;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 5px;
  background-color: rgba(255, 255, 255, 0.911);
  border: 1px solid #ccc;
  text-align: center;
  height: 670px;
  width: 500px;
  z-index: 100;
  opacity: 0; /* 初期状態は透明 */
  transition: opacity 3.0s ease;
}
.popup.show {
  opacity: 1; /* 表示時は不透明 */
}

.leaflet{
  width: 350px;
  height: 500px;
  margin: 5px
}
.home {
  text-align: left;
  padding-left: 20px;
}

.home::after {
  content: "";
  position: fixed;
  left: 50%;
  top: 60%;
  width: 80px;
  height: 80px;
  background: url(../assets/img04.png) no-repeat center/cover;
  z-index: -1;
  opacity: 1;
  transform: translate(-50%, -50%);
  animation: fadeIn 3s forwards;
}


h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}


.carousel {
  margin-top: 20px;
}

.carousel-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.el-carousel__item h3 {
  color: #475669;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
  text-align: center;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.information {
  text-align: center;
  margin-left: 100px;
  margin-right: 100px;
  background-color: none;
}


.scrollbar-demo-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  margin-left: 50px;
  margin-right: 50px;
  text-align: center;
  background: none;
  font-size: small;
  border-bottom: 2px solid;
}

.twitter {
  margin-bottom: 100px;
  padding-left: 50px;
}

.rakkenlogo {
  width: 200px;
  height: 200px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
}

/*スマホ表示の際に収まらない部分などはここで調整。*/
@media screen and (max-width: 640px) {
  .popup {
    height: 550px;
    width: 400px;
  }
  
  .leaflet{
    width: 270px;
    height: 380px;
    margin: 5px
  }

  .carousel_item,
  .carousel-image {
    height: 400px;
  }

  .information {
    border-radius: 10px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .scrollbar-demo-item {
    margin-left: 10px;
    margin-right: 10px;
  }
}</style>
