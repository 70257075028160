<template>
  <div class="thisyear">
    <h1>第94回わせだ寄席</h1>
    <h2>学生限定・入場無料！！</h2>
    <h3>※終了いたしました。</h3>
    <p>たくさんのご来場ありがとうございました!!</p>

    
    <!--
    <a href="https://docs.google.com/forms/d/e/1FAIpQLSdnq8ymMc2ks3sVxrJxKrNGQciccE-Mt4e1RxlwgqM5Xj7m9A/viewform?usp=sf_link" target="_blank">ご予約はこちらから！！</a>
    <p>※学生限定となります。</p>]-->
    
   <img class="leaflet" src="../assets/img30.jpg" >
  

  
  </div>

  <div class="map">
    <h2>会場はこちら</h2>
    <p><iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12958.939256065507!2d139.71047874879133!3d35.708142861252554!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188d1cb93f8869%3A0x6067ebc016cae4d5!2z5bCP6YeO6KiY5b-16Kyb5aCC!5e0!3m2!1sja!2sjp!4v1720270856110!5m2!1sja!2sjp"
        height="250px" width="350px" style="border:0;" allowfullscreen="" loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"></iframe></p>
  </div>
</template>

<script>
export default {
  name: 'ThisYear',
};
</script>

<style scoped>
.thisyear {
  margin-top: 50px;
  padding-left: 20px;
  box-sizing: border-box;
}

.map {
  text-align: center;
}

.leaflet {
  text-align: center;
  margin-bottom: 200px;
  width:500px; 
  height:700px; 
  margin:20px;
}

/*スマホ表示の際に収まらない部分などはここで調整。*/
@media screen and (max-width: 640px){
  .leaflet {
    width:330px; 
    height:480px; 
    margin: 5px;
  }
}
</style>